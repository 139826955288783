
/*
 *		setGmap v 2
 *
 *		Descrizione
 *			Gestisce la creazione della mappa interattiva di Google, la geolocalizzazione dell'utente, e il calcolo dell'itinerario dalla posizione dell'utente.
 *		
 *		Parametri da indicare sul div della mappa
 *			data-zoom: indica lo zoom da 0 a 19
 *			data-location: indica la posizione della struttura tramite indirizzo o coordinate (lat, lng)
 *			data-message: indica il testo da far comparire nell'infowindow. Di base c'è sempre il nome del sito
 *			
 *		Nota
 *			Salvataggio della posizione dell'utente in una session storage per snellire il caricamento delle pagine.
 *
 */


(function($){

	$.fn.setGMap= function(settings){
	
		var $this = $(this),
			map,
			geocoder = new google.maps.Geocoder(),
			marker,
			position_start,
			position_end;
	
		settings = jQuery.extend({
			ID: $this.attr('id'),
			zoom: $this.attr('data-zoom'),
			address: $this.attr('data-location'),
			message: $this.attr('data-message'),
			message_status: true,											// TRUE: usa infowindow
			route: true,													// TRUE: usa calcola percorso
			route_container: 'map_container',								// ID del contenitore del calcola percorso
			route_manual: true,											// FALSE: usa geolocalizzazione - TRUE: inserimento manuale della posizione dell'utente
			route_manual_ID: 'position_manual_box',							// ID del box che contine gli input per inserimento manuale della posizione dell'utente
			route_ID: 'position_box',										// ID del box che contine il bottone di calcola pecorso
			route_stamp_ID: 'map_route',									// ID del box dove viene stampato il percorso
		},settings);
		
		
		/*
		 *	Funzione di inizializzazione della mappa
		 */
		
		function mapInitialize(){
		
			if(!settings.zoom) settings.zoom = "15";
				
			if(settings.ID){
			
				if(settings.address){
				
					geocoder.geocode( {'address': settings.address}, function(results, status){
					
						if (status == google.maps.GeocoderStatus.OK) {
						
							position_end = results[0].geometry.location;
							
							var myOptions = {
								zoom: parseInt(settings.zoom),
								center: position_end,
								navigationControl: true,
								mapTypeControl: false,
								mapTypeId: google.maps.MapTypeId.ROADMAP,
								panControl: true,
								zoomControl: true,
								zoomControlOptions: {
									style: google.maps.ZoomControlStyle.SMALL,
									position: google.maps.ControlPosition.TOP_LEFT
								},
								scaleControl: true,
								streetViewControl: false,
								draggable: true,
								disableDoubleClickZoom: false,
								scrollwheel: false,
								disableDefaultUI: false
							};
							
							//attivazione mappa
							map = new google.maps.Map(document.getElementById( settings.ID ), myOptions);
							
							//rimuove gli hotel (tutti i punti collegati al business) dalla mappa
							map.set('styles', [
								{
									featureType: "poi.business",
									elementType: "labels",
									stylers: [{ visibility: "off" }]
								}
							]);
							
							//settaggio marker
							marker = new google.maps.Marker({
								position: position_end,
								map: map,
								draggable:false
							});
							
							if(settings.message){
								addInfo(marker, settings.message, position_end, settings.message_status);
							}
							
							if(settings.route){
								takeGeolocation();
							}

						} else {
							alert("Geocode was not successful for the following reason: " + status);
						}
					});

				}
			}else{
			
				alert("Indicare l'ID della mappa");
				
			}
			
		}
		

		/*
		 *	Funzione di creazione del infowindow
		 *	INPUT: marker, messaggio da visualizzare all'interno dell'infowindow, posizione del puntatore, apertura automatica dell'infowindow (true) o al click (false).
		 */
		 
		function addInfo(marker, message, position, open){
		
			var infowindow = new google.maps.InfoWindow({ 
				content: message,
				position: position
			});
			
			if(open) infowindow.open(map,marker);
			
			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open(map,marker);
			});
				
		}
		
		/*
		 *	Funzione per la geolocalizzazione dell'utente
		 *	INPUT: posizione di arrivo del luogo
		 */
		function takeGeolocation(){	

			$('#'+settings.route_container).hide();
		
			// controllo supporto per il SessionStorage
			if(sessionStorage == null){
			
				// se non e' supportato -> mando messaggio di errore
				
			}else{
			
				// se tutto funziona:
				// -> controllo se la posizione e' gia' salvata
				if(sessionStorage.getItem('edita_geolocation') == null){
				
					// controllo se devo geolocalizzare automaticamente o manualmente
					// -> se route_manual == false geolocalizzo automaticamente
					if(settings.route_manual == false){

						// no-->geolocalizzo l'utente, salvo la posizione in SessionStorage e setto position_start
						if(navigator.geolocation){	// il browser supporta la geolocalizzazione
							
							navigator.geolocation.getCurrentPosition(function(new_position){
							
								position_start = new google.maps.LatLng(new_position.coords.latitude,new_position.coords.longitude);
								
								sessionStorage.setItem('edita_geolocation',position_start); 

								$('#'+settings.route_manual_ID).hide();
								$('#'+settings.route_ID).fadeIn();
								$('#'+settings.route_container).fadeIn();
								
							}, function(){
							
								manualPosition();
								
							});
							
						}else{	// il browser non supporta la geolocalizzazione
						
							manualPosition();
							
						}
					}else{	// se route_manual == true no geolocalizzazione ma compare form di inserimento indirizzo
					
						manualPosition();
	
					}

				}else{
				
					var coords = sessionStorage.getItem('edita_geolocation').replace('(','').replace(')','').replace(' ','').split(',');
					position_start = new google.maps.LatLng(coords[0],coords[1]);
			
					$('#'+settings.route_container).fadeIn();
					$('#'+settings.route_manual_ID).hide();
					$('#'+settings.route_ID).fadeIn();

				}
				
				$('#'+settings.route_ID).find('button').click( function(){
					calculateRoute();
				});
			}
		}
		
		function manualPosition(){
			
			$('#'+settings.route_container).fadeIn();
			$('#'+settings.route_ID).hide();
			$('#'+settings.route_manual_ID).fadeIn();
		
			$('#'+settings.route_manual_ID).find('button').click( function(){
			
				var address_start = $('#'+settings.route_manual_ID).find("input[type='text']").attr("value");
				
				geocoder.geocode( {'address': address_start}, function(results, status){
				
					if (status == google.maps.GeocoderStatus.OK) {
						
						position_start = results[0].geometry.location;
						
						sessionStorage.setItem('edita_geolocation', position_start); 
						
						map.setCenter(position_start);
						
						var marker_start = new google.maps.Marker({
							map: map, 
							position: position_start,
							draggable:false
						});
						
						$('#'+settings.route_ID).fadeIn();
						$('#'+settings.route_manual_ID).hide();

					} else {
						alert("Geocode was not successful for the following reason: " + status);
					}
				});
				
			});
		
		}
		
			
		/*
		 *	Funzione di generazione del percorso
		 *	INPUT: posizione di partenza dell'utente, posizione di arrivo del luogo
		 */
		function calculateRoute(){
			
			var directionsService = new google.maps.DirectionsService(),
				directionsDisplay = new google.maps.DirectionsRenderer();
	
			//settaggio marker
			var marker_start = new google.maps.Marker({
				position: position_end,
				map: map,
				draggable:false
			});
			
			//console.log(position_start);
				
			var request = {
				origin: position_start, 
				destination: position_end,
				travelMode: google.maps.DirectionsTravelMode.DRIVING
			};
				
 			directionsDisplay.setMap(map);
			directionsDisplay.setPanel(document.getElementById(settings.route_stamp_ID));
			
			directionsService.route(request, function(response, status){
			
				if (status == google.maps.DirectionsStatus.OK){
					directionsDisplay.setDirections(response);
					
					$('#'+settings.route_ID).hide();
				}
				
			});		
		}
		
		
		return mapInitialize();
		
	}
	
})(jQuery);