/**
*   funzioni di tracciamento analytics (no form, che sono gestiti in fase di validazione)
*/

function trackEverything()
{
    var filetypes = /\.(zip|pdf|doc.*|xls.*|ppt.*|mp3|txt|rar|wma|mov|avi|wmv|wav)$/i;
    
    
    jQuery('body').on('click', 'a', function(event)
    {
        var the_element = jQuery(this);
        var track = true;
        var href = the_element.attr('href');

        // match se il link è interno al dominio
        var isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);
        
        var elEv = [];
    
        if (href.match(/^mailto\:/i)) 
        {   
            elEv.category = 'email';
            elEv.action = 'click';
            elEv.label = href.replace(/^mailto\:/i, '');
        }
        else if (href.match(filetypes)) 
        {
            var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
            elEv.category = 'download';
            elEv.action = 'click-' + extension[0];
            elEv.label = href.replace(/ /g,'-');
        }
        else if (href.match(/^https?\:/i) && !isThisDomain) 
        {
            elEv.category = 'external';
            elEv.action = 'click';
            elEv.label = href.replace(/^https?\:\/\//i, '');
            
        }
        else track = false;
        
        
        // se ho fatto return false, forzo redirect dopo aver tracciato
        gaHitCallbackHandler = function() 
        {
            window.location.href = href;
        }
        
        if(track)
        {
            var ret = true;
            
            if((elEv.category == 'external' || elEv.category == 'download') && (the_element.attr('target') == undefined || the_element.attr('target').toLowerCase() != '_blank') ) 
            {
                try
                {
//                    console.log('1 '+elEv.category.toLowerCase());
//                    console.log('2 '+elEv.action.toLowerCase());
//                    console.log('3 '+elEv.label.toLowerCase());
                    ga('send','event', elEv.category.toLowerCase(),elEv.action.toLowerCase(),elEv.label.toLowerCase(),
                       {
                           'hitCallback':gaHitCallbackHandler
                       });
                }
                catch(e){ gaHitCallbackHandler(); }
                ret = false;
            }
            else 
            {
                try
                {
//                    console.log('1 '+elEv.category.toLowerCase());
//                    console.log('2 '+elEv.action.toLowerCase());
//                    console.log('3 '+elEv.label.toLowerCase());
                    ga('send','event', elEv.category.toLowerCase(),elEv.action.toLowerCase(),elEv.label.toLowerCase());
                }
                catch(e){}
            }
            
            return ret;
        }
    });
}



jQuery(document).ready(function()
{
    trackEverything();
});