/**
 *
 * Custom function
 *
 */

(function($) {

    /*
     *	Funzione per rendere un box tutto cliccabile. ATTENZIONE: all'interno del box deve esserci un unico link
     */

    $.edt_allClick = function() { 
        var clickbox = $('.allclick');
        clickbox.each(function() {
            var $this = $(this);
            $this.css('cursor', 'pointer');
            $this.find('a').click(function(event) {
                event.preventDefault();
            });

            if ($this.find('a').attr('target') == '_blank') {
                $this.click(function() {
                    window.open($('a', this).attr('href'));
                });
            } else {
                $this.click(function() {
                    window.location.href = $('a', this).attr('href');
                });
            }
        });
    }

    /*
     *	Caricamento ajax delle immagini dal marcatore [data-loadimg] e dei background dal marcatore [data-loadbackground]
     */

    $.edt_loadAsynchronousImage = function() {

        $('*[data-loadimg]').each(function() {
            var box = $(this),
                src = box.attr('data-loadimg'),
                alt = '',
                img = new Image();

            if (box.attr('data-alt')) {
                alt = box.attr('data-alt');
            }

            $(img).load(function() {
                box.append('<img src="' + src + '" alt="' + alt + '" width="' + img.width + '" height="' + img.height + '">');
                $('img', box).hide().fadeIn();

            }).attr('src', src);
        });

        $('*[data-loadbackground]').each(function() {
            var box = $(this),
                src = box.attr('data-loadbackground');

            box.css({
                'background-image': 'url(' + src + ')',
                'background-repeat': 'no-repeat',
                'background-position': 'top center'
            });

            if (box.has(['data-position'])) {
                box.css({
                    'background-position': box.attr('data-position'),
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            } else {
                box.css({
                    'background-position': 'top center',
                    'background-image': 'url(' + src + ')',
                    'background-repeat': 'no-repeat'
                });
            }

            if (box.attr('data-repeat') == 'repeat') {
                box.css({
                    'background-repeat': 'repeat'
                });
            } else {
                box.css({
                    'background-repeat': 'no-repeat'
                });
            }
        });
    }


    /*
     *	Funzione per la gestione dei bottoni "condividi" legati ai social
     */

    $.edt_socialOpen = function() {
        $('[data-socialurl]').each(function() {
            var $this = $(this),
                url = $this.attr('data-socialurl');

            $this.click(function() {
                window.open(url, '', 'width=500, height=500');
            });

        });
    }

    $.edt_trackAdwordsCampaign = function() {
        //$('body').append('<img height="1" width="1" style="border-style:none;" alt="" src=""/>');
    }


    /*
     *	Funzione per l'apertura di una finestra modale tramite cookie
     */

    $.edt_openModal = function() {
        if($('#onloadmodal').length) {
            if (!$.cookie("openmodal")) {
                $('#onloadmodal').modal();
                $.cookie("openmodal", 'no', {
                    path: '/'
                });
            }
        }
    }



    /*
     *	Funzione per l'apertura di un iframe all'interno di una finestra modale
     */

    $.edt_iframeModalOpen = function() {
        $('.videoModal').each(function() {
            // impostiamo gli attributi da aggiungere all'iframe es: data-src andrà ad impostare l'url dell'iframe
            $('.modalButton').on('click', function(e) {
                var src = $(this).attr('data-src');
                var width = $(this).attr('data-width') || 640; // larghezza dell'iframe se non impostato usa 640
                var height = $(this).attr('data-height') || 360; // altezza dell'iframe se non impostato usa 360

                var allowfullscreen = $(this).attr('data-video-fullscreen'); // impostiamo sul bottone l'attributo allowfullscreen se è un video per permettere di passare alla modalità tutto schermo

                // stampiamo i nostri dati nell'iframe
                $(".videoModal iframe").attr({
                    'src': src,
                    'height': height,
                    'width': width,
                    'allowfullscreen': ''
                });
            });

            // se si chiude la modale resettiamo i dati dell'iframe per impedire ad un video di continuare a riprodursi anche quando la modale è chiusa
            $(this).on('hidden.bs.modal', function() {
                $(this).find('iframe').html("");
                $(this).find('iframe').attr("src", "");
            });
        });
    }

    /*
     *	Funzione per la formattazione delle tabelle con classe listino
     *	tramite le classi di bootstrap
     */

    $.edt_responsiveTable = function() {

        $('.entry-content table').each(function() {
            var $this = $(this);
            $this.addClass('table');
            $this.wrap('<div class="table-responsive"></div>');
        });

        if ($(window).width() <= 767) {

            $('#primary .table-responsive').prepend(
                '<span class="angle-scroll-left">' +
                '<i class="icon icon-edt-arrow-right-6 angle-scroll-left__animation"></i>' +
                '</span>'
            );

            $('#primary .table-responsive').on('scroll', function(event) {
                var angleScrollLeft = $('.angle-scroll-left');

                if ($(this).scrollLeft() >= 1) {
                    angleScrollLeft.addClass('fade-out');
                } else {
                    angleScrollLeft.removeClass('fade-out');
                }
            });

            $('#primary').find('.table-responsive').each(function() {
                var table = $(this);
                $('.angle-scroll-left').click(function(event) {
                    table.animate({
                        scrollLeft: '+=60'
                    }, 200);
                });
            });
        }
    }

    /*
     *	Funzione per l'inizializzazione della mappa
     */
    $.edt_initializeGmap = function() {
        $('*[data-location]').each(function() {
            var map = $(this),
                address = map.attr('data-location');
            map.setGMap({
                address: address
            });
        });
    }

    /*
    *	Attraverso il Google Loader carico il modulo mappa
    */
    $.edt_loadGmap = function() {
        // fix per permettere la reimpostazione della mappa ad ogni caricamento di pagina
        sessionStorage.removeItem('edita_geolocation');
        
        $.getScript('https://www.google.com/jsapi', function() {
            if(url.key) {
                var map_params = 'language=' + url.lingua + '&key=' + url.key;
            }
            else {
                var map_params = 'language=' + url.lingua;
            }
            google.load('maps', '3', {
                other_params: map_params,
                callback: function() {
                    $.edt_initializeGmap();
                }
            });
        });
    }


    /**
     * Funzione per l'attivazione del menu responsive
     */

    $.edt_responsiveMenu = function(viewportSize) {
        $('.main-navigation').find('a[href="#"]').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
            });
        });

        if ($(window).width() <= viewportSize) { // se minore o uguale a 1199px di default
            var menuPrimaryOffcanvas = $("#js-menu-primary-offcanvas");

            $('.main-navigation').find('a[href="#"]').each(function() {
                $(this).click(function(event) {
                    $('ul.sub-menu').removeClass('open');
                    $(this).next().toggleClass('open');
                });
            });

            $("#js-menu-offcanvas-button-open").click(function() {
                menuPrimaryOffcanvas.addClass("offcanvas-open");
            });

            $("#js-menu-offcanvas-button-close").click(function() {
                menuPrimaryOffcanvas.removeClass("offcanvas-open");
            });
        }
    }

    $.edt_scrollAnimations = function(desktopScrollTop, desktopScrollBol) {
        var header = $('#js-header'),
            menu = $('#js-fixed-menu'),
            desktopFixedMenu = $('#js-desktopFixedMenu'),
            windowWidth = $(window).width();

        if ($('body').is('.is-mobile')) {
            var headerH = $('#js-header').outerHeight(), // Height compreso di margini e bordi
                menuH = $('#js-fixed-menu').innerHeight(), // Height senza margini e bordi
                scrollValue = headerH - menuH;

            header.css('height', headerH);

            $(window).scroll(function() {
                scrolltop = $(window).scrollTop();

                if ( scrolltop >= scrollValue) {

                    menu.addClass("scroll");
                
                } else {
                    menu.removeClass("scroll");
                }            
            });

        } else if ( desktopScrollBol ) {
            header.addClass("scroll");
        }
    }


    /*
     *  Funzioni per PhotoSwiper
     */
    $.edt_addImageSizeForPhotoSwiper = function() {
        var thumbs = $('img[data-big-size]');
        thumbs.each(function(index) {
            if ($(this).parent('a').length > 0) {
                var isImageLink = $(this).parent('a').attr('href').match(/\.(jpeg|jpg|gif|png)$/);
                if (isImageLink) {
                    big_size = $(this).data('big-size');
                    link = $(this).parent('a');
                    link.attr('data-size', big_size);
                    link.wrap('<div class="single-image"></div>');
                }
            }
        });
    }

    $.edt_initPhotoSwiper = function(selector) {
        var galleryItems = $(selector);
        var pswpItems = [];
        galleryItems.each(function(index) {
            var dimensions = $(this).find('a').data('size').split('x');
            var width = parseInt(dimensions[0], 10);
            var height = parseInt(dimensions[1], 10);

            myImage = {
                src: $(this).find('a').attr('href'),
                w: width,
                h: height,
                msrc: $(this).find('img').attr('src')
            };
             
            pswpItems.push(myImage);
        });


        galleryItems.each(function(index) {
            $(this).click(function(evt) {
                evt.preventDefault();
                $.edt_openPhotoSwipe(pswpItems, index);
            });
        });
    }

    $.edt_openPhotoSwipe = function(galleryItems, index) {
        var options = {
            index: index
        };
        var pswpElement = $('.pswp')[0];

        var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, galleryItems, options);
        gallery.init();
    }


    $.edt_smoothScroll = function() {
        $("a[href*='#']").not("[href='#'],[data-toggle]").click(function() {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {

                    if(target.attr('data-paddingTopScroll')) {
                        var adjust = target.attr('data-paddingTopScroll');
                    }
                    else {
                        var adjust = target.css('padding-top') ? target.css('padding-top').replace('px', '') : 0;
                    }
                    
                    $('html, body').animate({
                        scrollTop: target.offset().top - adjust
                    }, 1000);
                    
                    return false;
                }
            }
        });
    }

    $.edt_startOwl = function(selector) {
        $(selector).each(function(arguments) {
            var owl = $(this);
            var params = {
                navigation : owl.data('owl-navigation'),
                slideSpeed : owl.data('owl-slide-speed'),
                autoPlay : owl.data('owl-autoplay'),
                pagination : owl.data('owl-pagination'),
                paginationSpeed : owl.data('owl-pagination-speed'),
                transitionStyle : owl.data('owl-transition'),
                scrollPerPage : owl.data('owl-scroll-per-page'),
                autoHeight : false,
                lazyLoad : true,
                navigationText: [ 
                    "<i class='icon-edt-arrow-left-2'></i>", 
                    "<i class='icon-edt-arrow-right-2'></i>" 
                ]
            };

            if(owl.data('owl-single-item')) {
                params['singleItem'] = true;
            } else {
                params['items'] = owl.data('owl-items-xl'); //numero item da 1680 in su
                params['itemsDesktop'] = [1679,owl.data('owl-items-lg')]; //numero item tra 1679 e 1200
                params['itemsDesktopSmall'] = [1199,owl.data('owl-items-md')]; //numero item tra 1199 e 992 (tablet landscape)
                params['itemsTablet'] = [991,owl.data('owl-items-sm')]; //numero item tra 991 e 768 (tablet portrait)
                params['itemsMobile'] = [767,owl.data('owl-items-xs')]; //numero item da 767 in giu (cell)
            }

            if(owl.hasClass('sync')){
                params['afterAction'] = $.edt_syncOwl
            } 

            owl.owlCarousel(params);

            if(owl.data('owl-prev')){
                $(owl.data('owl-prev')).click(function(){
                    owl.trigger('owl.prev');
                });
            }
            if(owl.data('owl-next')){
                $(owl.data('owl-next')).click(function(){
                    owl.trigger('owl.next');
                });
            }
        });
    }

    $.edt_syncOwl = function(elem) {
        var current = this.currentItem;
        var syncID = elem.data('owl-sync');
        var owlToSync = $('.owl-carousel[data-owl-sync="' + syncID +'"]');
        $(owlToSync).trigger('owl.goTo', current);
    }

    /*****************************************************************************************/

    $.edt_toConsole = function(string) {
        if ((typeof window.console == "undefined")) {
            alert(string);
        } else console.log(string);
    }

    function $j(argument) {
        var result = jQuery(argument);
        if (result.length === 0) return null;
        else return result;
    }

    $.edt_translateCountdown = function( langCountdown ) {

        countdown.resetLabels();
        
        if ( langCountdown == 'it' ) {
            countdown.setLabels(
                ' millisecondo| secondo| minuto| ora| giorno| settimana| mese| anno| decade| secolo| millennio',
                ' millisecondi| secondi| minuti| ore| giorni| settimane| mesi| anni| decadi| secoli| millenni',
                ' e ',
                ' '
            );
        } else if ( langCountdown == 'de' ) {
            countdown.setLabels(
                ' Millisekunde| zweite| Minute| jetzt| Tag| Woche| Monat| Jahr| Jahrzehnt| Jahrhundert| Jahrtausend',
                ' Millisekunden| Sekunden| Minuten| Stunden| Tage| Wochen| Monate| Alter| Jahrzehnte| Jahrhunderte| Jahrtausende',
                ' und ',
                ' '
            );
        } else if ( langCountdown == 'fr' ) {
            countdown.setLabels(
                ' milliseconde| deuxième| minute| maintenant| jour| semaine| mois| année| décennie| siècle| millénaire',
                ' millisecondes| secondes| minutes| heures| journées| semaines| mois| âge| décennies| siècles| millénaires',
                ' et ',
                ' '
            );
        }

    }

	$.edt_edtCountdown = function(){        

		jQuery( ".edt_countdown_class" ).find('.item').each( function(){ 
            
		    var dataInizio = "";
		    var questo = jQuery(this);
		    jQuery.post(
			    
			    url.ajax_url, 
			    {
			        'action': 'edita_ajax_get_start_countdown_date',
			        'offerId':   questo.data('offerid'),			        
			    }, 
			    
			    function(response){
				    
					setInterval(function() {
                        $.edt_stampaCountdown( questo, response );

                    }, 3600);
			    }
			);
	    });
	}

	$.edt_stampaCountdown = function( item, response ){

        jQuery( item ).find( '.pageTimer' ).empty().append(
            moment().countdown(response, countdown.DAYS|countdown.HOURS).toString()
        );        
	} 
    
    $.edt_closePhotoswipe = function () {
	    $('.pswp__button--close').click(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
	
	    $(window).scroll(function() {
	        if($('.pswp').hasClass('pswp--open')) {
	            $('.pswp').removeClass('pswp--supports-fs pswp--open pswp--notouch pswp--css_animation pswp--svg pswp--animated-in pswp--visible pswp--zoom-allowed pswp--has_mouse');
	        }
	    });
    }
    
    $j(document).ready(function() {

        $.edt_allClick();
        $.edt_responsiveTable();
        $.edt_addImageSizeForPhotoSwiper();
        $.edt_iframeModalOpen();
        $.edt_responsiveMenu(1199);
        $.edt_scrollAnimations(300, false);
        $.edt_startOwl('.owl-carousel');
        $.edt_smoothScroll();

		$.edt_closePhotoswipe();

        var mixFilter = $('.mix-filter-support');
        if ( mixFilter.length ) mixitup(mixFilter);
        

        if ( typeof lang && lang != 'en' ) {
            $.edt_translateCountdown( lang );
		}

        $.edt_edtCountdown();

        $('.gform_wrapper form').each(function() {
            $(this).checkEditaForm();
        });

		
        $('.checkNewsletter').each(function() {
            $(this).checkEditaForm();
        });
	    

		
    });


    $j(window).load(function() {
        $.edt_loadGmap();

        var gallery = $('.gallery');

        if ( gallery.length ) {
            gallery.each(function( index ) {
                
                $.edt_initPhotoSwiper( "#" + $( this ).attr('id') + " .gallery-item");
            });
        }

        $.edt_initPhotoSwiper(".entry-content .single-image");

    });

    $j(window).resize(function() {



    });

})(jQuery);
